import { Stack } from '@lyra/core/components'
import BodyText from '@lyra/core/components/BodyText'
import formatUSD from '@lyra/core/utils/formatUSD'
import { MarketId } from '@lyra/web/constants/markets'
import { CurrencyId } from '@lyra/web/constants/tokens'
import { forwardRef, Ref, useState } from 'react'

type Props = {
  midpointPrice: number
  currencyId: CurrencyId
  onPress?: () => void
}

const OrderbookMidpoint = forwardRef(function TradeOrderbookMidpoint(
  { midpointPrice, currencyId, onPress }: Props,
  ref: Ref<HTMLElement>
) {
  const [isHover, setIsHover] = useState(false)
  return (
    <Stack
      ref={ref}
      style={{
        position: 'sticky',
        bottom: 0,
        top: 0,
      }}
      alignItems="center"
      width="100%"
      $mobile={{
        marginTop: '$3',
      }}
      hoverStyle={
        onPress
          ? {
              cursor: 'pointer',
            }
          : undefined
      }
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      <Stack
        style={{
          position: 'absolute',
          right: 0,
          left: 0,
          top: 15,
          height: 1,
        }}
        backgroundColor="$hairline"
      />
      <Stack
        style={{
          borderRadius: '$1',
          position: 'relative',
          zIndex: 1,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 6,
          paddingBottom: 6,
        }}
        borderColor="$hairline"
        borderWidth={1}
        backgroundColor={isHover && onPress ? '$hoverBg' : '$appBg'}
      >
        <BodyText>
          {formatUSD(midpointPrice, {
            dps: currencyId === MarketId.DOGE ? 4 : undefined,
          })}
        </BodyText>
      </Stack>
    </Stack>
  )
})

export default OrderbookMidpoint

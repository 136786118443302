import Notice from '@lyra/core/components/Notice'
import formatUSD from '@lyra/core/utils/formatUSD'
import { Ticker } from '@lyra/web/constants/instruments'
import { OrderParamsWithoutSignature, Quote } from '@lyra/web/constants/order'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { getBestPrice, getMinOrMaxPrice } from '@lyra/web/utils/instruments'

import { getInvalidReasonWarning } from '../../utils/order'

type Props = {
  ticker: Ticker
  limitPrice: number
  isBuy: boolean
  orderParams?: OrderParamsWithoutSignature
  quote?: Quote
  error?: string
}

export default function OrderbookWarningNotice({
  ticker,
  limitPrice,
  isBuy,
  orderParams,
  quote,
  error,
}: Props) {
  const { subaccount } = useSubaccount()

  const isUnsupportedCurrency =
    subaccount && subaccount.margin_type === 'PM' && subaccount.currency !== ticker.base_currency

  const bestPrice = getBestPrice(ticker, isBuy)
  const minOrMaxPrice = getMinOrMaxPrice(ticker, isBuy)
  const isNoQuoteWithDefault = !bestPrice && minOrMaxPrice === limitPrice

  const orderWarning =
    quote && !quote.is_valid
      ? getInvalidReasonWarning(quote.invalid_reason)
      : isUnsupportedCurrency
      ? `Cannot trade ${ticker.base_currency} markets on ${subaccount.currency} Portfolio Margin account. Use a Standard Margin account or create a ${ticker.base_currency} Portfolio Margin account.`
      : isNoQuoteWithDefault
      ? 'There is no liquidity for the selected instrument. You should manually set your limit price before submitting your order.'
      : null

  const isTPSLOrder = orderParams && !!orderParams.trigger_type
  const orderTriggerPrice = orderParams?.trigger_price ? +orderParams.trigger_price : 0

  const isPerpsTriggerPriceGreaterThanMarkPrice =
    (isBuy && orderParams?.trigger_type === 'stoploss') ||
    (!isBuy && orderParams?.trigger_type === 'takeprofit')

  const isPerpsValidTriggerPrice = isPerpsTriggerPriceGreaterThanMarkPrice
    ? orderTriggerPrice > +ticker.mark_price
    : orderTriggerPrice < +ticker.mark_price

  const isPerpsValidLimitPrice = isBuy
    ? limitPrice > orderTriggerPrice
    : limitPrice < orderTriggerPrice

  const perpsTakeProfitStopLossError =
    isTPSLOrder && orderTriggerPrice !== 0 && !isPerpsValidTriggerPrice
      ? `Your trigger price must be ${
          isPerpsTriggerPriceGreaterThanMarkPrice ? 'above' : 'below'
        } the current mark price: ${formatUSD(ticker.mark_price)}`
      : isTPSLOrder && limitPrice !== 0 && !isPerpsValidLimitPrice
      ? `Your limit price must be ${isBuy ? 'above' : 'below'} the trigger price: ${formatUSD(
          orderTriggerPrice
        )}`
      : null

  return (
    <>
      {ticker.base_currency === 'DOGE' ? (
        <Notice
          status="warning"
          message={
            'The DOGE-PERP market is deprecated and its price has been fixed as of 00:00 UTC July 31st. You have 30 days to close your DOGE positions.'
          }
          width="100%"
        />
      ) : null}
      {perpsTakeProfitStopLossError ? (
        <Notice status="error" message={perpsTakeProfitStopLossError} width="100%" />
      ) : error ? (
        <Notice status="error" message={error} width="100%" />
      ) : orderWarning ? (
        <Notice status="warning" message={orderWarning} width="100%" />
      ) : null}
    </>
  )
}

import fetchPublicTradeHistory from '@lyra/core/api/fetchPublicTradeHistory'
import { TradeSettledPublicResponseSchema } from '@lyra/core/api/types/public.get_trade_history'
import Section from '@lyra/core/components/Section'
import { Trades } from '@lyra/web/constants/instruments'
import useInstrumentTradeFeed from '@lyra/web/hooks/useInstrumentTradeFeed'
import { useEffect, useRef, useState } from 'react'
import { StackProps } from 'tamagui'

import TradeFeedTable from './TradeFeedTable'

const MAX_FEED_LENGTH = 100

type Props = {
  instrumentName: string
  tickSize: number
  stepSize: number
} & StackProps

const TRADE_HISTORY_PAGES = 2

function createArray(n: number): number[] {
  const arr = []
  for (let i = 1; i <= n; i++) {
    arr.push(i)
  }
  return arr
}

export default function TradeFeed({ instrumentName, tickSize, stepSize, ...stackProps }: Props) {
  const [allTrades, setAllTrades] = useState<Trades | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const currentInstrumentName = useRef(instrumentName)
  const newTrades = useInstrumentTradeFeed(instrumentName)

  useEffect(() => {
    const fetchTradeHistory = async () => {
      const instrumentChanged = currentInstrumentName.current !== instrumentName
      if ((!newTrades && !allTrades) || instrumentChanged) {
        // initial one-time fetch
        setIsFetching(true)
        const trades = (
          await Promise.all(
            createArray(TRADE_HISTORY_PAGES).map(async (page) => {
              const res = await fetchPublicTradeHistory({
                instrument_name: instrumentName,
                page,
                page_size: 100,
              })
              return res.result.trades
            })
          )
        ).flat()
        const takerTrades = Object.values(trades).filter(
          (trade) => trade.liquidity_role === 'taker'
        )

        const dedupedTrades = Object.values(
          takerTrades.reduce(
            (allTrades, trade) => {
              if (!allTrades[trade.trade_id]) {
                allTrades[trade.trade_id] = trade
              }
              return allTrades
            },
            {} as Record<string, TradeSettledPublicResponseSchema>
          )
        ).sort((a, b) => b.timestamp - a.timestamp)
        currentInstrumentName.current = instrumentName
        setAllTrades(dedupedTrades)
        setIsFetching(false)
      } else if (newTrades && newTrades.length > 0) {
        const newTradeHistory =
          allTrades && allTrades.length > 0
            ? [...newTrades, ...allTrades.slice(newTrades.length, MAX_FEED_LENGTH)]
            : [...newTrades]
        newTradeHistory.sort((a, b) => b.timestamp - a.timestamp)
        setAllTrades(newTradeHistory)
      }
    }

    fetchTradeHistory()
  }, [allTrades, instrumentName, newTrades])

  if (!allTrades || isFetching) {
    return <Section.Spinner {...stackProps} />
  }

  return (
    <TradeFeedTable {...stackProps} trades={allTrades} tickSize={tickSize} stepSize={stepSize} />
  )
}

import { PublicGetInviteCodeResultSchema } from '@lyra/core/api/types/public.get_invite_code'
import { PublicGetPointsResultSchema } from '@lyra/core/api/types/public.get_points'
import { PublicGetPointsLeaderboardResultSchema } from '@lyra/core/api/types/public.get_points_leaderboard'
import { SECONDS_IN_MINUTE } from '@lyra/core/constants/time'

export type PointsResult = PublicGetPointsResultSchema
export type LeaderboardData = PublicGetPointsLeaderboardResultSchema
export type UserRank = PublicGetPointsResultSchema
export type InviteCode = PublicGetInviteCodeResultSchema

// 10,000 points per $1 fees paid
export const POINTS_PER_FEE_DOLLAR = 10_000
// Points round 2 - 1 point per $1 per day
export const ACCOUNT_VALUE_POINTS_RATE_HOURLY = 1 / 24
// 1 point per $1 per day
export const YIELD_POINTS_RATE_HOURLY = 1 / 24

// Points update every 15 minutes
export const POINTS_UPDATE_INTERVAL_MS = 15 * SECONDS_IN_MINUTE * 1_000

export type PointsEpoch = {
  round: number
  label: string
  startTimestamp: number
  endTimestamp: number
  levels: [LevelConfig, ...LevelConfig[]]
  hidePctPoints?: boolean
}

export type LevelConfig = {
  id: number
  cutoff: number
  level: number
  sublevel: number
  levelName: string
  color: 'gray' | 'blue' | 'gold' | 'green' | 'red'
}

export type Points = {
  trading: number
  referral: number
  account_value: number
  lrt_swell_coveredcall: number
  lrt_etherfi_coveredcall: number
  lrt_kelp_coveredcall: number
  susde_ethena_longpp: number
  lrt_etherfi_callspread: number
  lrt_etherfi_longpp: number
  lombard_lbtc_ps: number
  lombard_lbtc_cs: number
}

export const EMPTY_POINTS: Points = {
  trading: 0,
  referral: 0,
  account_value: 0,
  lrt_swell_coveredcall: 0,
  lrt_etherfi_coveredcall: 0,
  lrt_kelp_coveredcall: 0,
  susde_ethena_longpp: 0,
  lrt_etherfi_callspread: 0,
  lrt_etherfi_longpp: 0,
  lombard_lbtc_ps: 0,
  lombard_lbtc_cs: 0,
}

export type PointsData =
  | {
      isRegistered: true
      isIgnored: boolean
      isYieldProgram: boolean
      totalPoints: number
      points: Points
      pointsRaw: PointsResult
    }
  | {
      isRegistered: false
      isIgnored?: undefined
      isYieldProgram?: undefined
      totalPoints?: undefined
      points?: undefined
      pointsRaw?: undefined
    }

export type LeaderboardRow = {
  rank: number | 'max'
  username: string
  isCustomUsername: boolean
  flag: string
  points: number
  parentUsername?: string
  isMe?: boolean
}

export const MAX_LEADERBOARD_ROWS = 250

const round1LevelsConfig: [LevelConfig, ...LevelConfig[]] = [
  // NPC
  {
    id: 1,
    cutoff: 0,
    level: 1,
    sublevel: 1,
    levelName: 'NPC',
    color: 'gray',
  },
  {
    id: 2,
    cutoff: 2500,
    level: 1,
    sublevel: 2,
    levelName: 'NPC',
    color: 'gray',
  },
  {
    id: 3,
    cutoff: 5000,
    level: 1,
    sublevel: 3,
    levelName: 'NPC',
    color: 'gray',
  },
  // Degen
  {
    id: 4,
    cutoff: 10000,
    level: 2,
    sublevel: 1,
    levelName: 'Degen',
    color: 'gray',
  },
  {
    id: 5,
    cutoff: 15000,
    level: 2,
    sublevel: 2,
    levelName: 'Degen',
    color: 'gray',
  },
  {
    id: 6,
    cutoff: 20000,
    level: 2,
    sublevel: 3,
    levelName: 'Degen',
    color: 'gray',
  },
  // Ape
  {
    id: 7,
    cutoff: 40_000,
    level: 3,
    sublevel: 1,
    levelName: 'Ape',
    color: 'blue',
  },
  {
    id: 8,
    cutoff: 60_000,
    level: 3,
    sublevel: 2,
    levelName: 'Ape',
    color: 'blue',
  },
  {
    id: 9,
    cutoff: 80_000,
    level: 3,
    sublevel: 3,
    levelName: 'Ape',
    color: 'blue',
  },
  // Gigabrain
  {
    id: 10,
    cutoff: 130_000,
    level: 4,
    sublevel: 1,
    levelName: 'Gigabrain',
    color: 'gold',
  },
  {
    id: 11,
    cutoff: 180_000,
    level: 4,
    sublevel: 2,
    levelName: 'Gigabrain',
    color: 'gold',
  },
  {
    id: 12,
    cutoff: 250_000,
    level: 4,
    sublevel: 3,
    levelName: 'Gigabrain',
    color: 'gold',
  },
  // Jedi
  {
    id: 13,
    cutoff: 500_000,
    level: 5,
    sublevel: 1,
    levelName: 'Jedi',
    color: 'green',
  },
  {
    id: 14,
    cutoff: 750_000,
    level: 5,
    sublevel: 2,
    levelName: 'Jedi',
    color: 'green',
  },
  {
    id: 15,
    cutoff: 1_000_000,
    level: 5,
    sublevel: 3,
    levelName: 'Jedi',
    color: 'green',
  },
  // Sith
  {
    id: 16,
    cutoff: 2_500_000,
    level: 6,
    sublevel: 1,
    levelName: 'Sith',
    color: 'red',
  },
  {
    id: 17,
    cutoff: 5_000_000,
    level: 6,
    sublevel: 2,
    levelName: 'Sith',
    color: 'red',
  },
  {
    id: 18,
    cutoff: 10_000_000,
    level: 6,
    sublevel: 3,
    levelName: 'Sith',
    color: 'red',
  },
]

const round2LevelsConfig: [LevelConfig, ...LevelConfig[]] = [
  // Apprentice
  {
    id: 1,
    cutoff: 0,
    level: 1,
    sublevel: 1,
    levelName: 'Apprentice',
    color: 'gray',
  },
  {
    id: 2,
    cutoff: 100_000,
    level: 1,
    sublevel: 2,
    levelName: 'Apprentice',
    color: 'gray',
  },
  {
    id: 3,
    cutoff: 200_000,
    level: 1,
    sublevel: 3,
    levelName: 'Apprentice',
    color: 'gray',
  },
  // Explorer
  {
    id: 4,
    cutoff: 500_000,
    level: 2,
    sublevel: 1,
    levelName: 'Explorer',
    color: 'blue',
  },
  {
    id: 5,
    cutoff: 1_000_000,
    level: 2,
    sublevel: 2,
    levelName: 'Explorer',
    color: 'blue',
  },
  {
    id: 6,
    cutoff: 1_500_000,
    level: 2,
    sublevel: 3,
    levelName: 'Explorer',
    color: 'blue',
  },
  // Warrior
  {
    id: 7,
    cutoff: 2_500_000,
    level: 3,
    sublevel: 1,
    levelName: 'Warrior',
    color: 'red',
  },
  {
    id: 8,
    cutoff: 5_000_000,
    level: 3,
    sublevel: 2,
    levelName: 'Warrior',
    color: 'red',
  },
  {
    id: 9,
    cutoff: 7_500_000,
    level: 3,
    sublevel: 3,
    levelName: 'Warrior',
    color: 'red',
  },
  // Master
  {
    id: 10,
    cutoff: 15_000_000,
    level: 4,
    sublevel: 1,
    levelName: 'Master',
    color: 'gold',
  },
  {
    id: 11,
    cutoff: 30_000_000,
    level: 4,
    sublevel: 2,
    levelName: 'Master',
    color: 'gold',
  },
  {
    id: 12,
    cutoff: 50_000_000,
    level: 4,
    sublevel: 3,
    levelName: 'Master',
    color: 'gold',
  },
  // Sovereign
  {
    id: 13,
    cutoff: 100_000_000,
    level: 5,
    sublevel: 1,
    levelName: 'Sovereign',
    color: 'green',
  },
  {
    id: 14,
    cutoff: 250_000_000,
    level: 5,
    sublevel: 2,
    levelName: 'Sovereign',
    color: 'green',
  },
  {
    id: 15,
    cutoff: 500_000_000,
    level: 5,
    sublevel: 3,
    levelName: 'Sovereign',
    color: 'green',
  },
]

export const pointsEpochs: PointsEpoch[] = [
  {
    round: 1,
    label: 'epoch-1',
    startTimestamp: 1715126400000,
    endTimestamp: 1717545600000,
    levels: round1LevelsConfig,
  },
  {
    label: 'epoch-2',
    round: 2,
    startTimestamp: 1717545600000,
    endTimestamp: 1719964800000,
    levels: round2LevelsConfig,
  },
  {
    label: 'epoch-3',
    round: 3,
    startTimestamp: 1719964800000,
    endTimestamp: 1722384000000,
    levels: round2LevelsConfig,
    hidePctPoints: false,
  },
  {
    label: 'epoch-4',
    round: 4,
    startTimestamp: 1722384000000,
    endTimestamp: 1724803200000,
    levels: round2LevelsConfig,
    hidePctPoints: false,
  },
  {
    label: 'epoch-5',
    round: 5,
    startTimestamp: 1724803200000,
    endTimestamp: 1726617600000,
    levels: round2LevelsConfig,
    hidePctPoints: false,
  },
  {
    label: 'epoch-6',
    round: 6,
    startTimestamp: 1726617600000,
    endTimestamp: 1729638000000,
    levels: round2LevelsConfig,
    hidePctPoints: true,
  },
]

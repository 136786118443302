import Section from '@lyra/core/components/Section'
import { MarketId } from '@lyra/web/constants/markets'
import useOrderbook from '@lyra/web/hooks/useOrderbook'
import { OrderbookGroup } from '@lyra/web/utils/orderbook'
import { StackProps } from 'tamagui'

import OrderbookTable, { BidOrAsk } from './OrderbookTable'

type Props = {
  marketId: MarketId
  instrumentName: string
  tickGroup: OrderbookGroup
  tickSize: number
  amountStep: number
  numTicks?: number
  onPressOrder?: (order: BidOrAsk) => void
} & StackProps

const Orderbook = ({
  marketId,
  tickSize,
  amountStep,
  tickGroup,
  instrumentName,
  numTicks,
  onPressOrder,
  ...stackProps
}: Props) => {
  const orderbook = useOrderbook(instrumentName, tickGroup)

  return orderbook ? (
    <OrderbookTable
      numTicks={numTicks}
      marketId={marketId}
      tickSize={tickSize}
      amountStep={amountStep}
      orderbook={orderbook}
      onPressOrder={onPressOrder}
      {...stackProps}
    />
  ) : (
    <Section.Spinner {...stackProps} />
  )
}

export default Orderbook

import BodyText from '@lyra/core/components/BodyText'
import Table, { createColumnHelper, TableData } from '@lyra/core/components/Table'
import formatDateTime from '@lyra/core/utils/formatDateTime'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatUSD from '@lyra/core/utils/formatUSD'
import { Trades } from '@lyra/web/constants/instruments'
import { countDecimals } from '@lyra/web/utils/number'
import React, { useMemo } from 'react'
import { StackProps } from 'tamagui'

export type Props = {
  trades: Trades
  tickSize: number
  stepSize: number
} & StackProps

export type TradeFeedTableData = TableData<{
  id: string
  side: string
  price: number
  size: number
  time: number
}>

const TradeFeedTable = ({ trades, tickSize, stepSize, ...tableProps }: Props): JSX.Element => {
  const rows: TradeFeedTableData[] = useMemo(() => {
    return trades.map((trade) => {
      return {
        id: trade.trade_id,
        side: trade.direction,
        price: parseFloat(trade.trade_price),
        size: parseFloat(trade.trade_amount),
        time: trade.timestamp,
      }
    })
  }, [trades])

  const columnHelper = createColumnHelper<TradeFeedTableData>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('side', {
        id: 'side',
        header: 'Side',
        meta: {
          minWidth: 30,
        },
        cell: (props) => {
          const { side } = props.cell.row.original
          return (
            <BodyText color={side === 'buy' ? 'green' : 'red'}>
              {side === 'buy' ? 'Buy' : 'Sell'}
            </BodyText>
          )
        },
      }),
      columnHelper.accessor('price', {
        id: 'price',
        header: 'Price',
        meta: {
          minWidth: 80,
        },
        cell: (props) => {
          const { price } = props.cell.row.original
          return <BodyText>{formatUSD(price, { dps: countDecimals(tickSize) })}</BodyText>
        },
      }),
      columnHelper.accessor('size', {
        id: 'size',
        header: 'Size',
        meta: {
          minWidth: 50,
        },
        cell: (props) => {
          const { size } = props.cell.row.original
          return <BodyText>{formatNumber(size, { dps: countDecimals(stepSize) })}</BodyText>
        },
      }),
      columnHelper.accessor('time', {
        id: 'time',
        header: 'Time',
        meta: {
          minWidth: 110,
          justifyContent: 'flex-end',
        },
        cell: (props) => {
          const { time } = props.cell.row.original
          return (
            <BodyText color="secondary">
              {formatDateTime(time, { hideMins: false, includeTimezone: false, hideYear: true })}
            </BodyText>
          )
        },
      }),
    ],
    [columnHelper, stepSize, tickSize]
  )
  return (
    <Table
      {...tableProps}
      size="sm"
      data={rows}
      columns={columns}
      emptyMessage="No recent trades"
    />
  )
}

export default TradeFeedTable

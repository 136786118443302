import { SECONDS_IN_WEEK } from '@lyra/core/constants/time'
import { Address } from 'viem'

export type OpRewardsUser = {
  address: Address
  startTimestamp: number
  endTimestamp: number
  fees: number
  feesRank: number
  volume: number
  trades: number
  multiplier: number
  opRewards: number
  points: number
}

export type OpRewardsStats = {
  startTimestamp: number
  endTimestamp: number
  totalFees: number
  totalTrades: number
  totalVolume: number
  totalTraders: number
  lastUpdatedTimestamp: number
}

export type OpRewardsConfig = {
  startTimestamp: number
  endTimestamp: number
  totalOpRewards: number
  tiers: {
    rank: number
    multiplier: number
  }[]
}

export const OP_REWARDS_ROUNDS: OpRewardsConfig[] = [
  {
    startTimestamp: 1726617600000,
    endTimestamp: 1727222400000,
    totalOpRewards: 10_000,
    tiers: [{ rank: 25, multiplier: 2 }],
  },
  {
    startTimestamp: 1727222400000,
    endTimestamp: 1727827200000,
    totalOpRewards: 15_000,
    tiers: [
      { rank: 25, multiplier: 2 },
      { rank: 50, multiplier: 1.5 },
    ],
  },
  {
    startTimestamp: 1727827200000,
    endTimestamp: 1727827200000 + SECONDS_IN_WEEK * 1000,
    totalOpRewards: 10_000,
    tiers: [
      { rank: 25, multiplier: 2 },
      { rank: 50, multiplier: 1.5 },
    ],
  },
  {
    startTimestamp: 1728432000000,
    endTimestamp: 1728432000000 + SECONDS_IN_WEEK * 1000,
    totalOpRewards: 10_000,
    tiers: [
      { rank: 25, multiplier: 2 },
      { rank: 50, multiplier: 1.5 },
    ],
  },
]

export const getEmptyOpRewardsStats = (config: OpRewardsConfig): OpRewardsStats => ({
  startTimestamp: config.startTimestamp,
  endTimestamp: config.endTimestamp,
  totalFees: 0,
  totalTrades: 0,
  totalVolume: 0,
  totalTraders: 0,
  lastUpdatedTimestamp: Date.now(),
})

export const MIN_OP_REWARDS = 1

import { parseAsFloat, useQueryState } from 'next-usequerystate'
import { useCallback, useMemo } from 'react'

import { QueryParam } from '../constants/query'
import { roundToStep } from '../utils/number'

export default function useTradeLimitPriceInput(
  stepSize?: number
): [number, (limitPrice: number) => void] {
  const [_limitPrice, _setLimitPrice] = useQueryState(QueryParam.LimitPrice, parseAsFloat)
  const limitPrice = _limitPrice ? _limitPrice : 0
  const limitPriceRounded = stepSize ? roundToStep(limitPrice, stepSize) : limitPrice
  const setLimitPrice = useCallback(
    (limitPrice: number) => {
      if (!limitPrice) {
        _setLimitPrice(null)
        return
      }
      const limitPriceRounded = stepSize ? roundToStep(limitPrice, stepSize) : limitPrice
      _setLimitPrice(limitPriceRounded)
    },
    [_setLimitPrice, stepSize]
  )
  return useMemo(() => [limitPriceRounded, setLimitPrice], [limitPriceRounded, setLimitPrice])
}

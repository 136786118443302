import { useEffect, useRef, useState } from 'react'

import { Trade, Trades } from '../constants/instruments'
import { getTradesChannel } from '../utils/trades'
import useWebSocket from './useWebSocket'

const POLL_MS = 30000

export default function useInstrumentTradeFeed(instrumentName: string) {
  const { isReady, subscribe, unsubscribe } = useWebSocket()

  const [trades, setTrades] = useState<Trades>()
  const tradesRef = useRef<Record<string, Trade>>({})

  // Subscribe to instruments
  const channel = getTradesChannel(instrumentName)

  useEffect(() => {
    if (isReady) {
      tradesRef.current = {}
      setTrades(undefined)
      subscribe([
        {
          channel,
          onMessage: (data: Trades) => {
            if (tradesRef.current) {
              data.forEach((d) => {
                tradesRef.current[d.trade_id] = d
              })
            }
          },
        },
      ])
    }
    return () => {
      if (isReady) {
        unsubscribe([channel])
      }
    }
  }, [channel, isReady, subscribe, trades, unsubscribe])

  useEffect(() => {
    const interval = setInterval(() => {
      setTrades(tradesRef.current ? [...Object.values(tradesRef.current)] : undefined)
    }, POLL_MS)
    return () => clearInterval(interval)
  }, [])

  return trades
}

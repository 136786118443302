import { DropdownProps } from '@lyra/core/components/Dropdown'
import { SectionProps } from '@lyra/core/components/Section'
import { OPTIONS_HEADER_HEIGHT } from '@lyra/web/constants/layout'
import useNewAccount from '@lyra/web/hooks/useNewAccount'
import useSubaccount from '@lyra/web/hooks/useSubaccount'

import SubaccountDropdownSection from '../common/SubaccountDropdownSection'

type Props = {
  placement?: DropdownProps['placement']
} & SectionProps

export default function TradeSubaccountSection(sectionProps: Props) {
  const { subaccounts, subaccount, switchSubaccount } = useSubaccount()

  const { isNoAuth, isAuthWithNoFirstDeposit, isAuthWithNoFirstTransfer } = useNewAccount()

  if (
    isNoAuth ||
    isAuthWithNoFirstDeposit ||
    isAuthWithNoFirstTransfer ||
    !subaccount ||
    Object.values(subaccounts).length < 2
  ) {
    return null
  }

  return (
    <SubaccountDropdownSection
      {...sectionProps}
      showAccountValue
      showActions
      subaccountId={subaccount.subaccount_id}
      onSelect={switchSubaccount}
      height={OPTIONS_HEADER_HEIGHT}
    />
  )
}

import BodyText from '@lyra/core/components/BodyText'
import { getDefaultButtonIconSize } from '@lyra/core/components/Button'
import Button from '@lyra/core/components/Button'
import Dropdown from '@lyra/core/components/Dropdown'
import { DropdownButtonProps } from '@lyra/core/components/DropdownButton'
import Heading from '@lyra/core/components/Heading'
import Icon, { ChevronDown, ChevronUp } from '@lyra/core/components/Icon'
import Section, { SectionProps } from '@lyra/core/components/Section'
import formatUSD from '@lyra/core/utils/formatUSD'
import useAuth from '@lyra/web/hooks/useAuth'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import { useState } from 'react'
import { XStack } from 'tamagui'

import DepositModal from './DepositModal'
import SubaccountsDropdownItems from './SubaccountsDropdownItems'
import TransferModal from './TransferModal'

type Props = {
  showAccountValue?: boolean
  isHeading?: boolean
  showActions?: boolean
  placement?: DropdownButtonProps['placement']
  subaccountId?: number | null
  onSelect: (subaccountId: number) => void
  isStandardMarginOnly?: boolean
  ignoreSubaccountIds?: number[]
} & SectionProps

export default function SubaccountDropdownSection({
  showAccountValue,
  isHeading,
  showActions,
  placement,
  subaccountId,
  onSelect,
  isStandardMarginOnly,
  ignoreSubaccountIds,
  ...sectionProps
}: Props) {
  const { isAuthenticated } = useAuth()
  const { subaccountDatas } = useSubaccount()

  const label =
    subaccountId && subaccountId in subaccountDatas
      ? subaccountDatas[subaccountId].label
      : isAuthenticated
      ? 'Account 1'
      : 'No Account'

  const subaccountValue =
    subaccountId && subaccountId in subaccountDatas
      ? subaccountDatas[subaccountId].subaccountValue
      : 0

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isDepositOpen, setIsDepostOpen] = useState(false)
  const [isTransferOpen, setIsTransferOpen] = useState(false)

  return (
    <>
      <Dropdown
        onChangeOpen={setIsDropdownOpen}
        placement={placement}
        trigger={
          <Section
            {...sectionProps}
            cursor="pointer"
            hoverStyle={{ backgroundColor: '$hoverBg' }}
            pressStyle={{ backgroundColor: '$pressBg' }}
            backgroundColor={isDropdownOpen ? '$hoverBg' : '$appBg'}
            justifyContent="center"
          >
            <Section.XStack alignItems="center" justifyContent="space-between">
              {isHeading ? (
                <Heading size="h3">{label}</Heading>
              ) : (
                <BodyText size="lg">{label}</BodyText>
              )}
              <XStack gap="$1" alignItems="center">
                {showAccountValue ? (
                  isHeading ? (
                    <Heading size="h3">{formatUSD(subaccountValue)}</Heading>
                  ) : (
                    <BodyText size="lg">{formatUSD(subaccountValue)}</BodyText>
                  )
                ) : null}
                <Icon
                  icon={isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
                  size={isHeading ? 24 : getDefaultButtonIconSize('lg')}
                  color="secondary"
                />
              </XStack>
            </Section.XStack>
          </Section>
        }
      >
        <SubaccountsDropdownItems
          subaccountId={subaccountId}
          onSelect={onSelect}
          isStandardMarginOnly={isStandardMarginOnly}
          ignoreSubaccountIds={ignoreSubaccountIds}
          size="lg"
        />
        {showActions ? (
          <XStack
            style={{ position: 'sticky', bottom: 0 }}
            borderTopColor="$hairline"
            borderTopWidth={1}
            gap="$3"
            padding="$3"
            alignSelf="center"
            width="100%"
            backgroundColor="$hoverBg"
          >
            <Button
              flexGrow={1}
              flexShrink={1}
              flexBasis={0}
              label="Deposit"
              onPress={() => setIsDepostOpen(true)}
            />
            <Button
              flexGrow={1}
              flexShrink={1}
              flexBasis={0}
              label="Transfer"
              onPress={() => setIsTransferOpen(true)}
            />
          </XStack>
        ) : null}
      </Dropdown>
      {isDepositOpen ? <DepositModal onClose={() => setIsDepostOpen(false)} /> : null}
      {isTransferOpen ? (
        <TransferModal
          defaultFromSubaccountId="funding"
          defaultToSubaccountId={subaccountId ?? undefined}
          onClose={() => setIsTransferOpen(false)}
        />
      ) : null}
    </>
  )
}

import tryRequest from '@lyra/core/api/tryRequest'
import {
  PrivateGetTradeHistoryParamsSchema,
  PrivateGetTradeHistoryResponseSchema,
  Trades,
} from '@lyra/core/api/types/private.get_trade_history'
import { LyraAuthHeaders } from '@lyra/core/constants/api'

import { MarketId } from '../constants/markets'

export function getTradesChannel(instrument_name: string) {
  return `trades.${instrument_name}`
}

export function getMarketTradesChannel(instrumentType: string, marketId: MarketId) {
  return `trades.${instrumentType}.${marketId}.settled`
}

const DEFAULT_LIMIT = 200
const BATCH_SIZE = 5
const PAGE_SIZE = 200

export const fetchUserTradeHistory = async (
  _params: Omit<PrivateGetTradeHistoryParamsSchema, 'page' | 'page_size'> & { limit?: number },
  authHeaders: LyraAuthHeaders
): Promise<Trades> => {
  const { limit = DEFAULT_LIMIT, ...params } = _params
  const maxPages = Math.ceil(limit / PAGE_SIZE)

  let page = 1
  let allTrades: Trades = []

  while (true) {
    const responses = await Promise.all(
      Array.from({ length: BATCH_SIZE }, (_, i) => page + i).map(async (pageNum) => {
        const response = await tryRequest<
          PrivateGetTradeHistoryParamsSchema,
          PrivateGetTradeHistoryResponseSchema
        >(
          '/private/get_trade_history',
          {
            ...params,
            page: pageNum,
            page_size: PAGE_SIZE,
          },
          { authHeaders }
        )
        if (response.result.pagination.num_pages < pageNum || pageNum > maxPages) {
          return []
        }

        return response.result.trades
      })
    )

    const newTrades = responses.flat()
    allTrades = [...allTrades, ...newTrades]
    if (newTrades.length < PAGE_SIZE * BATCH_SIZE) {
      break
    }

    page += BATCH_SIZE
  }

  return allTrades
}

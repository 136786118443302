import { XStack } from '@lyra/core/components'
import BodyText from '@lyra/core/components/BodyText'
import VideoText from '@lyra/core/components/VideoText'
import useHover from '@lyra/core/hooks/useHover'
import usePress from '@lyra/core/hooks/usePress'
import { Pathname } from '@lyra/web/constants/pages'
import { useRouter } from 'next/navigation'
import React, { useEffect } from 'react'
import { StackProps } from 'tamagui'

type TabParams = {
  path?: Pathname
  onPress?: () => void
  isSelected?: boolean
  name: string
}

type TabProps = TabParams & {
  isBodyText?: boolean
} & StackProps

type TabSize = 'sm' | 'md' | 'lg'

type Props = {
  pages: TabParams[]
  size?: TabSize
  tabProps?: StackProps
  isBodyText?: boolean
} & StackProps

const Tab = ({ isSelected, name, path, onPress, isBodyText, ...stackProps }: TabProps) => {
  const { prefetch, push } = useRouter()

  useEffect(() => {
    if (path) {
      prefetch(path)
    }
  }, [prefetch, path])

  const { isHovering, handleHoverIn, handleHoverOut } = useHover()
  const { isPressing, handlePressIn, handlePressOut } = usePress()

  const color = isPressing ? 'inverted' : isSelected || isHovering ? 'primary' : 'secondary'

  return (
    <XStack
      {...stackProps}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      borderBottomWidth={1}
      borderColor={isSelected ? '$invertedBg' : 'transparent'}
      backgroundColor={isPressing ? '$invertedBg' : isHovering ? '$hoverBg' : undefined}
      onPress={onPress ? onPress : path ? () => push(path) : undefined}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      {isBodyText ? (
        <BodyText color={color}>{name}</BodyText>
      ) : (
        <VideoText color={color}>{name}</VideoText>
      )}
    </XStack>
  )
}

const getPadding = (size: TabSize) => {
  switch (size) {
    case 'sm':
      return {
        paddingHorizontal: '$2',
        paddingVertical: '$1.5',
      }
    case 'md':
      return {
        paddingHorizontal: '$3',
        paddingVertical: '$2',
      }
    case 'lg':
      return {
        paddingHorizontal: '$3',
        paddingVertical: '$3',
      }
  }
}

export default function TabsHorizontal({
  pages,
  size = 'md',
  tabProps,
  isBodyText,
  ...stackProps
}: Props) {
  return (
    <XStack {...stackProps}>
      {pages.map(({ path, onPress, isSelected, name }) => {
        return (
          <Tab
            key={name}
            {...getPadding(size)}
            {...tabProps}
            path={path}
            onPress={onPress}
            isSelected={isSelected}
            name={name}
            isBodyText={isBodyText}
          />
        )
      })}
    </XStack>
  )
}

import BodyText from '@lyra/core/components/BodyText'
import Icon, { Check } from '@lyra/core/components/Icon'
import Table, { createColumnHelper, TableData, TableMarkerRow } from '@lyra/core/components/Table'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatTruncatedNumber from '@lyra/core/utils/formatTruncatedNumber'
import { Orderbook } from '@lyra/web/constants/instruments'
import { MarketId } from '@lyra/web/constants/markets'
import useTradeLimitPriceInput from '@lyra/web/hooks/useTradeLimitPriceInput'
import { countDecimals } from '@lyra/web/utils/number'
import { formatOrderPrice } from '@lyra/web/utils/order'
import { useMemo } from 'react'
import { StackProps, useTheme, XStack } from 'tamagui'

import OrderbookMidpoint from './OrderbookMidpoint'

const DEFAULT_NUM_TICKS = 10

export type BidOrAsk = {
  price: number
  size: number
  isBid: boolean
}

type Props = {
  marketId: MarketId
  tickSize: number
  amountStep: number
  orderbook: Orderbook
  numTicks?: number
  onPressOrder?: (order: BidOrAsk) => void
} & StackProps

type OrderbookData = {
  price: number
  size: number
  cumulativeSize: number
  isBid: boolean
}

const OrderbookTable = ({
  marketId,
  tickSize,
  amountStep,
  orderbook,
  numTicks = DEFAULT_NUM_TICKS,
  onPressOrder,
  ...stackProps
}: Props) => {
  const isNoLiquidity = orderbook.bids.length + orderbook.asks.length === 0
  const theme = useTheme()
  const isLargeOrderIncrement = amountStep >= 1_000
  const columnHelper = createColumnHelper<OrderbookData>()
  const columns = useMemo(
    () => [
      columnHelper.accessor('price', {
        header: 'Price',
        meta: {
          minWidth: 70,
          maxWidth: 70,
        },
        cell: (props) =>
          props.getValue() ? (
            <BodyText>{formatOrderPrice(props.getValue(), tickSize)}</BodyText>
          ) : (
            <BodyText opacity={0}>-</BodyText>
          ),
      }),

      columnHelper.accessor('size', {
        header: 'Size',
        meta: {
          justifyContent: 'flex-end',
        },
        cell: (props) =>
          props.getValue() ? (
            <BodyText>
              {isLargeOrderIncrement
                ? formatTruncatedNumber(props.getValue())
                : formatNumber(props.getValue(), { dps: countDecimals(amountStep) })}
            </BodyText>
          ) : null,
      }),
      columnHelper.accessor('cumulativeSize', {
        header: 'Cumulative',
        meta: {
          justifyContent: 'flex-end',
        },
        cell: (props) =>
          props.getValue() ? (
            <XStack alignItems="center" gap="$1">
              <BodyText textAlign="right">
                {isLargeOrderIncrement
                  ? formatTruncatedNumber(props.getValue())
                  : formatNumber(props.getValue(), { dps: countDecimals(amountStep) })}
              </BodyText>
              {props.row.original.isSelected ? (
                <Icon icon={<Check strokeWidth={2.5} />} size={13} color="primary" />
              ) : null}
            </XStack>
          ) : null,
      }),
    ],
    [amountStep, columnHelper, isLargeOrderIncrement, tickSize]
  )

  const asksTotalSize = useMemo(
    () => orderbook.asks.reduce((sum, a) => sum + parseFloat(a[1]), 0) ?? 1,
    [orderbook.asks]
  )
  const bidsTotalSize = useMemo(
    () => orderbook.bids.reduce((sum, b) => sum + parseFloat(b[1]), 0) ?? 1,
    [orderbook.bids]
  )

  const [limitPriceInput] = useTradeLimitPriceInput()

  const askRows: TableData<OrderbookData>[] = useMemo(() => {
    let cumulativeSize = 0
    // Sort in asc order for cumulative size count
    const rows = orderbook.asks.length
      ? orderbook.asks
          .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
          .map((ask) => {
            const size = parseFloat(ask[1])
            const price = parseFloat(ask[0])
            cumulativeSize += size
            const sizeBgPct = asksTotalSize > 0 ? (50 * size) / asksTotalSize : 0
            const cumSizePct = asksTotalSize > 0 ? (50 * cumulativeSize) / asksTotalSize : 0

            const background = `linear-gradient(90deg, transparent ${
              100 - cumSizePct
            }%, ${theme.redBg?.get()} ${100 - cumSizePct}% ${
              100 - sizeBgPct
            }%, ${theme.redDepthTick?.get()} ${sizeBgPct}%)`

            const hoverBackground = `linear-gradient(90deg, ${theme.hoverBg?.get()} ${
              100 - cumSizePct
            }%, ${theme.redBg?.get()} ${100 - cumSizePct}% ${
              100 - sizeBgPct
            }%, ${theme.redDepthTick?.get()} ${sizeBgPct}%)`

            const pressBackground = `linear-gradient(90deg, ${theme.pressBg?.get()} ${
              100 - cumSizePct
            }%, ${theme.redDepthTick?.get()} ${100 - cumSizePct}% ${
              100 - sizeBgPct
            }%, ${theme.redDepthTick?.get()} ${sizeBgPct}%)`

            const isSelected = limitPriceInput === price

            return {
              price,
              size,
              cumulativeSize,
              isBid: false,
              onPress: onPressOrder ? () => onPressOrder({ price, size, isBid: false }) : undefined,
              stackProps: {
                style: {
                  background: isSelected ? hoverBackground : background,
                },
                hoverStyle: {
                  background: hoverBackground,
                },
                pressStyle: {
                  background: pressBackground,
                },
              },
              isSelected,
              hoverBg: 'transparent',
              pressBg: 'transparent',
            }
          })
      : []

    if (rows.length < numTicks) {
      const numEmptyTicks = numTicks - rows.length
      const emptyTickRows: TableData<OrderbookData>[] = Array.from(Array(numEmptyTicks).keys()).map(
        () => ({
          price: 0,
          size: 0,
          cumulativeSize: 0,
          isBid: true,
        })
      )
      return [...rows, ...emptyTickRows]
    } else {
      return rows.slice(0, numTicks)
    }
  }, [
    orderbook.asks,
    asksTotalSize,
    onPressOrder,
    theme.redBg,
    theme.redDepthTick,
    theme.hoverBg,
    theme.pressBg,
    limitPriceInput,
    numTicks,
  ])

  const bidRows: TableData<OrderbookData>[] = useMemo(() => {
    let cumulativeSize = 0
    // Sort bids in asc order by price
    const rows = orderbook.bids.length
      ? orderbook.bids
          .sort((a, b) => parseFloat(b[0]) - parseFloat(a[0]))
          .map((bid) => {
            const size = parseFloat(bid[1])
            const price = parseFloat(bid[0])
            cumulativeSize += size
            const sizeBgPct = bidsTotalSize > 0 ? (50 * size) / bidsTotalSize : 0
            const cumSizePct = bidsTotalSize > 0 ? (50 * cumulativeSize) / bidsTotalSize : 0

            const background = `linear-gradient(90deg, transparent ${
              100 - cumSizePct
            }%, ${theme.greenBg?.get()} ${100 - cumSizePct}% ${
              100 - sizeBgPct
            }%, ${theme.greenDepthTick?.get()} ${sizeBgPct}%)`

            const hoverBackground = `linear-gradient(90deg, ${theme.hoverBg?.get()} ${
              100 - cumSizePct
            }%, ${theme.greenBg?.get()} ${100 - cumSizePct}% ${
              100 - sizeBgPct
            }%, ${theme.greenDepthTick?.get()} ${sizeBgPct}%)`

            const pressBackground = `linear-gradient(90deg, ${theme.pressBg?.get()} ${
              100 - cumSizePct
            }%, ${theme.greenDepthTick?.get()} ${100 - cumSizePct}% ${
              100 - sizeBgPct
            }%, ${theme.greenDepthTick?.get()} ${sizeBgPct}%)`

            const isSelected = limitPriceInput === price

            return {
              price,
              size,
              cumulativeSize,
              isBid: true,
              onPress: onPressOrder ? () => onPressOrder({ price, size, isBid: true }) : undefined,
              stackProps: {
                style: {
                  background: isSelected ? hoverBackground : background,
                },
                hoverStyle: {
                  background: hoverBackground,
                },
                pressStyle: {
                  background: pressBackground,
                },
              },
              isSelected,
              hoverBg: 'transparent',
              pressBg: 'transparent',
            }
          })
      : []

    if (rows.length < numTicks) {
      const numEmptyTicks = numTicks - rows.length
      const emptyTickRows: TableData<OrderbookData>[] = Array.from(Array(numEmptyTicks).keys()).map(
        () => ({
          price: 0,
          size: 0,
          cumulativeSize: 0,
          isBid: true,
        })
      )
      return [...rows, ...emptyTickRows]
    } else {
      return rows.slice(0, numTicks)
    }
  }, [
    orderbook.bids,
    numTicks,
    bidsTotalSize,
    theme.greenBg,
    theme.greenDepthTick,
    theme.hoverBg,
    theme.pressBg,
    limitPriceInput,
    onPressOrder,
  ])

  // Reverse ordering for table
  const rows: OrderbookData[] = useMemo(
    () => [...[...askRows].reverse(), ...bidRows],
    [askRows, bidRows]
  )

  const midpointPrice = useMemo(
    () =>
      bidRows.length && askRows.length
        ? (bidRows[0].price + askRows[0].price) / 2
        : bidRows.length
        ? bidRows[0].price
        : askRows.length
        ? askRows[0].price
        : null,
    [askRows, bidRows]
  )

  const rowMarker: TableMarkerRow | null = useMemo(() => {
    return midpointPrice
      ? {
          id: 'midpoint-marker',
          insertIndex: askRows.length,
          autoCenter: true,
          content: <OrderbookMidpoint midpointPrice={midpointPrice} currencyId={marketId} />,
        }
      : null
  }, [askRows.length, marketId, midpointPrice])

  return (
    <Table
      {...stackProps}
      data={isNoLiquidity ? [] : rows}
      columns={columns}
      markerRow={rows.length > 0 ? rowMarker : null}
      emptyMessage="No liquidity"
      size="sm"
    />
  )
}

export default OrderbookTable

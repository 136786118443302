import tryRequest, { RequestOptions } from './tryRequest'
import {
  PublicGetTradeHistoryParamsSchema,
  PublicGetTradeHistoryResponseSchema,
} from './types/public.get_trade_history'

export default async function fetchPublicTradeHistory(
  params: PublicGetTradeHistoryParamsSchema,
  options?: RequestOptions
): Promise<PublicGetTradeHistoryResponseSchema> {
  return tryRequest<PublicGetTradeHistoryParamsSchema, PublicGetTradeHistoryResponseSchema>(
    '/public/get_trade_history',
    params,
    options
  )
}

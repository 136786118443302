// Number of price levels returned
export enum OrderbookDepth {
  ONE = 1,
  TEN = 10,
  TWENTY = 20,
  ONE_HUNDRED = 100,
}

// Price grouping (rounding)
export enum OrderbookGroup {
  ONE = 1,
  TEN = 10,
  ONE_HUNDRED = 100,
}

export default function getOrderbookChannel(
  instrument_name: string,
  group: OrderbookGroup,
  depth: OrderbookDepth
) {
  return `orderbook.${instrument_name}.${group}.${depth}`
}

export const getDefaultGroup = (): OrderbookGroup => {
  return OrderbookGroup.ONE
}

import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import DropdownButton from '@lyra/core/components/DropdownButton'
import { X } from '@lyra/core/components/Icon'
import NumberInput from '@lyra/core/components/Input/NumberInput'
import LabelText from '@lyra/core/components/LabelText'
import Section from '@lyra/core/components/Section'
import { DEFAULT_ORDER_EXPIRY_DAYS, TimeInForce } from '@lyra/web/constants/order'
import { StackProps, XStack } from 'tamagui'

type Props = {
  timeInForce: TimeInForce
  onChangeTimeInForce: (timeInForce: TimeInForce) => void
  expiryDays: number
  onChangeExpiryDays: (expiryDays: number) => void
  onClose: () => void
} & StackProps

const TIME_IN_FORCE_DESCRIPTIONS: Record<TimeInForce, string> = {
  [TimeInForce.GoodTilCancelled]:
    "If your order doesn't execute, it will expire in the specified timeframe.",
  [TimeInForce.PostOnly]:
    'If your order will immediately fill against an existing order, it will be rejected, ensuring your order is added to the orderbook.',
  [TimeInForce.FillOrKill]:
    "If your order isn't completely filled immediately, it will be rejected.",
  [TimeInForce.IoC]:
    "If your order isn't completely or partially filled immediately, the unfilled portion will be cancelled.",
}

//  Order will be filled up to the limit price and the unfilled portion will get cancelled.

// TODO: @earthtojake make this a util
export const TIME_IN_FORCE_LABELS: Record<TimeInForce, string> = {
  [TimeInForce.GoodTilCancelled]: 'Good Til Time',
  [TimeInForce.PostOnly]: 'Post Only',
  [TimeInForce.FillOrKill]: 'Fill Or Kill',
  [TimeInForce.IoC]: 'IOC',
}

const TIME_IN_FORCE_TITLES: Record<TimeInForce, string> = {
  [TimeInForce.GoodTilCancelled]: 'Good Til Time',
  [TimeInForce.PostOnly]: 'Post Only',
  [TimeInForce.FillOrKill]: 'Fill Or Kill',
  [TimeInForce.IoC]: 'Immediate Or Cancel',
}

export const TIME_IN_FORCE_CUSTOM_EXPIRY = [TimeInForce.GoodTilCancelled, TimeInForce.PostOnly]

export default function OrderbookExpiryRow({
  timeInForce,
  onChangeTimeInForce,
  expiryDays,
  onChangeExpiryDays,
  onClose,
}: Props) {
  return (
    <Section.YStack>
      <XStack alignItems="center">
        <LabelText>Time in Force</LabelText>
        <XStack gap="$1" marginLeft="auto">
          <DropdownButton
            label={TIME_IN_FORCE_LABELS[timeInForce]}
            size="sm"
            dropdownProps={{ width: 280 }}
          >
            {Object.values(TimeInForce).map((timeInForceItem) => (
              <DropdownButton.ListItem
                key={timeInForceItem}
                label={TIME_IN_FORCE_TITLES[timeInForceItem]}
                sublabel={TIME_IN_FORCE_DESCRIPTIONS[timeInForceItem]}
                onPress={() => onChangeTimeInForce(timeInForceItem)}
                isSelected={timeInForceItem === timeInForce}
              />
            ))}
          </DropdownButton>
          <Button size="sm" icon={<X />} onPress={onClose} />
        </XStack>
      </XStack>
      {TIME_IN_FORCE_CUSTOM_EXPIRY.includes(timeInForce) ? (
        <XStack alignItems="center" justifyContent="space-between">
          <LabelText>Expires</LabelText>
          <NumberInput
            size="sm"
            value={expiryDays}
            defaultValue={DEFAULT_ORDER_EXPIRY_DAYS}
            onChangeValue={onChangeExpiryDays}
            width="40%"
            rightContent={<BodyText size="sm">Days</BodyText>}
          />
        </XStack>
      ) : null}
    </Section.YStack>
  )
}

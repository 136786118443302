import { useEffect, useRef, useState } from 'react'

import { Orderbook } from '../constants/instruments'
import { parseInstrumentName } from '../utils/instruments'
import getOrderbookChannel, { OrderbookDepth, OrderbookGroup } from '../utils/orderbook'
import useWebSocket from './useWebSocket'

const POLL_MS = 500

export default function useOrderbook(instrumentName: string, group: OrderbookGroup) {
  const { isReady, subscribe, unsubscribe } = useWebSocket()

  const [orderbook, setOrderbook] = useState<Orderbook>()
  const orderbookRef = useRef<Orderbook>()

  // Subscribe to instruments
  const channel = getOrderbookChannel(instrumentName, group, OrderbookDepth.ONE_HUNDRED)
  const parsedInstrument = parseInstrumentName(instrumentName)
  const type = parsedInstrument ? parsedInstrument.type : undefined

  useEffect(() => {
    if (isReady) {
      orderbookRef.current = undefined
      setOrderbook(undefined)
      subscribe([
        {
          channel,
          onMessage: (data) => {
            orderbookRef.current = data
          },
        },
      ])
    }
    return () => {
      if (isReady) {
        unsubscribe([channel])
      }
    }
  }, [channel, isReady, subscribe, unsubscribe])

  useEffect(() => {
    const interval = setInterval(
      () => setOrderbook(orderbookRef.current ? { ...orderbookRef.current } : undefined),
      POLL_MS
    )
    return () => clearInterval(interval)
  }, [type])

  return orderbook
}
